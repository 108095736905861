main {
    width: 70%;
    margin: auto;
    text-align: center;
}

main button {
    margin-top: 20px;
    background-color: #2d8cff;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
}

main button:hover {
    background-color: #0d60c5;
}

main h2 {
    margin-top: 40px;
    color: #2681f2;
    font-weight: 700;
    /* mobile ui*/
    font-size: 1.4rem;
    @media screen and (min-width: 768px) {
        font-size: 2rem;
    }
}

main h3 {
    margin: "20px 0";
    font-weight: 600;
    /* mobile ui*/
    font-size: 1.2rem;
    @media screen and (min-width: 768px) {
        font-size: 1.6rem;
    }
}
